import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from "../components/Homepage.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const FeatureCard = makeShortcode("FeatureCard");
const ArtDirection = makeShortcode("ArtDirection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`The Band`}</h2>
    <PageDescription mdxType="PageDescription">
      <p>{`Passenger Lounge is a two-piece musical act based out of Louisiana.`}</p>
      <p>{`Alec is on `}<inlineCode parentName="p">{`guitar`}</inlineCode>{`, `}<inlineCode parentName="p">{`bass guitar`}</inlineCode>{`, `}<inlineCode parentName="p">{`keyboard`}</inlineCode>{` and `}<inlineCode parentName="p">{`drum machine`}</inlineCode>{`, and Peter is on `}<inlineCode parentName="p">{`bass guitar`}</inlineCode>{`,
`}<inlineCode parentName="p">{`keyboard`}</inlineCode>{`, `}<inlineCode parentName="p">{`drum machine`}</inlineCode>{`, and `}<inlineCode parentName="p">{`saxophone`}</inlineCode>{`.`}</p>
    </PageDescription>
    <FeatureCard color="dark" href="/discography/viscous" title="Latest Release" actionIcon="arrowRight" className="homepage-feature" mdxType="FeatureCard">
      <ArtDirection mdxType="ArtDirection">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgT/2gAMAwEAAhADEAAAAcV6DeMG4K7/xAAXEAADAQAAAAAAAAAAAAAAAAAAAwQQ/9oACAEBAAEFApxeTi8//8QAFhEAAwAAAAAAAAAAAAAAAAAAAhAz/9oACAEDAQE/ASqv/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BZ//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABcQAAMBAAAAAAAAAAAAAAAAAAABMRD/2gAIAQEAAT8hlYUJWFD/2gAMAwEAAgADAAAAEFwf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEx/9oACAEDAQE/EFMf/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/EKC//8QAFxABAQEBAAAAAAAAAAAAAAAAADEBEP/aAAgBAQABPxBYxLljEn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "green vertical stripes",
            "title": "green vertical stripes",
            "src": "/static/07c106b62dd5b6318aa526a85e776b06/2e753/blue-stripes-mobile.jpg",
            "srcSet": ["/static/07c106b62dd5b6318aa526a85e776b06/69549/blue-stripes-mobile.jpg 288w", "/static/07c106b62dd5b6318aa526a85e776b06/473e3/blue-stripes-mobile.jpg 576w", "/static/07c106b62dd5b6318aa526a85e776b06/2e753/blue-stripes-mobile.jpg 1152w", "/static/07c106b62dd5b6318aa526a85e776b06/490e5/blue-stripes-mobile.jpg 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/aAAwDAQACEAMQAAABz6iMVIZ//8QAFhABAQEAAAAAAAAAAAAAAAAAAgAQ/9oACAEBAAEFAjHP/8QAFREBAQAAAAAAAAAAAAAAAAAAAhD/2gAIAQMBAT8Bc//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEAAT8hX//aAAwDAQACAAMAAAAQC8//xAAVEQEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAwEBPxCj/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QZ//EABgQAAMBAQAAAAAAAAAAAAAAAAABMXFB/9oACAEBAAE/EKRxgosP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "green vertical stripes",
            "title": "green vertical stripes",
            "src": "/static/bc122e0e337eebcb0f5eba1dbd451776/2e753/blue-stripes-tablet.jpg",
            "srcSet": ["/static/bc122e0e337eebcb0f5eba1dbd451776/69549/blue-stripes-tablet.jpg 288w", "/static/bc122e0e337eebcb0f5eba1dbd451776/473e3/blue-stripes-tablet.jpg 576w", "/static/bc122e0e337eebcb0f5eba1dbd451776/2e753/blue-stripes-tablet.jpg 1152w", "/static/bc122e0e337eebcb0f5eba1dbd451776/74f4b/blue-stripes-tablet.jpg 1728w", "/static/bc122e0e337eebcb0f5eba1dbd451776/1ca3f/blue-stripes-tablet.jpg 1920w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/aAAwDAQACEAMQAAABz6iMVIZ//8QAFhABAQEAAAAAAAAAAAAAAAAAAgAQ/9oACAEBAAEFAjHP/8QAFREBAQAAAAAAAAAAAAAAAAAAAhD/2gAIAQMBAT8Bc//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEAAT8hX//aAAwDAQACAAMAAAAQC8//xAAVEQEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAwEBPxCj/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QZ//EABgQAAMBAQAAAAAAAAAAAAAAAAABMXFB/9oACAEBAAE/EKRxgosP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "green vertical stripes",
            "title": "green vertical stripes",
            "src": "/static/bc122e0e337eebcb0f5eba1dbd451776/2e753/blue-stripes.jpg",
            "srcSet": ["/static/bc122e0e337eebcb0f5eba1dbd451776/69549/blue-stripes.jpg 288w", "/static/bc122e0e337eebcb0f5eba1dbd451776/473e3/blue-stripes.jpg 576w", "/static/bc122e0e337eebcb0f5eba1dbd451776/2e753/blue-stripes.jpg 1152w", "/static/bc122e0e337eebcb0f5eba1dbd451776/74f4b/blue-stripes.jpg 1728w", "/static/bc122e0e337eebcb0f5eba1dbd451776/1ca3f/blue-stripes.jpg 1920w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </ArtDirection>
    </FeatureCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      